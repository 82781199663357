.a{

    margin-left: -3px;

}
.reckangleA{
    transform: translateY(-100%) translateX(-10%);
}

.y{
    transform: translateY(30%);
}

.reckangleR{
    transform: translateY(-40%) translateX(-10%);
}

.ovalI{
    transform: translateY(-380%) translateX(-100%);

}

.g{
    transform: translateY(30%);
    margin-left: -3px;
}
.iPadding{
    margin-left: -4px;
}

.row.headerProgressSrc{
    margin-top: 15px;
}