.custom-input {
    position: relative;
}

.calendar-icon {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    font-size: 20px;
}

.custom-datepicker {
    display: flex;
}