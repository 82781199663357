@import './variables.css';

.App {
    align-content: center;
}

.maxLogoSize {
    max-width: 125px;
    max-height: 50px;
    height: 100%;
    width: auto;
}

html,body{
    height:100%;
    position: relative;
    overflow: hidden;

}
#root{
    height: 100%;
    position: relative;
}
.App-header{
    height: 100%;
    position: relative;
}
.WizardBox {

    min-height: 100%;
    position: relative;
    display: flex;

    /* Direction of the items, can be row or column */
    flex-direction: column;
}


.border{
    flex: 1;
    height: 100%;
    min-height: 100%;
    background-color: var(--primary-color);
    overflow: hidden;
    color: black;
    box-shadow: 0 4px 10px rgba(0,0,0,.25);
    -ms-overflow-style: none;
    scrollbar-width: none;
    display: flex;

    /* Direction of the items, can be row or column */
    flex-direction: column;
}
.wholeWizardContainer {
    flex: 1;
    height: 100%;
    display: flex;

    /* Direction of the items, can be row or column */
    flex-direction: column;
}

.container{
    flex: 1;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    position: relative;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;

}
/*.innerContentContainer{
    padding-left: 25px;

    position: relative;
    width: calc(100% - 50px);
    min-height: 100%;
    height: 100%;

}*/


.innerContentContainer{
    padding-left: 25px;
    padding-right: 25px;
    position: absolute;
    width: calc(100% - 50px);
    min-height: 100%;
    height: 100%;

}

.innerContainer{
    position: relative;
    height: 100%;
    min-height: 100%;

}

.headlineQuestions{
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 0.1em;
    font-size: 0.7em;
    font-family: Lato;
    padding-bottom: 9px;
   /* line-height: 1.35;
    letter-spacing: 0.1em;
    text-align: center;*/

}


.container .buttonContent{
    left: 0;
}

.buttonContent{
    /* Controls */
    box-sizing: border-box;

    /* Auto layout */
    /*padding: 15px 20px 0px;*/


    padding-top: 15px;
    padding-right: 25px;
    padding-bottom: 15px;
    padding-left: 25px;


    gap: 13px;
    z-index: 1;

    position: sticky;
    width: 100%;
    height: 80px;
    bottom: 0;

    /* Secondary */
    background: var(--primary-color);

    /* Secondary Dark */
    border-top: 1px solid var(--secondary-color);
}

.flexButtons{
    display: flex;
    justify-content: flex-end;
   /* flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;*/
}
/*.flexButtons{
    display: table-cell;
}*/


.flexContent{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
}

.headerContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0px 0px;
    left: 50%;
    position: sticky;
    z-index: 1;
    width: 100%;
    top: 0;
    margin-left: -175px;

    /* Secondary */
    background: var(--primary-color);
}

.innerContent{
    height: 70%;
    /*overflow-y: scroll;*/
    overflow-y: auto;
    overflow-x: hidden;

    scrollbar-width: thin;
    scrollbar-color: #888 var(--primary-color);

/* Internet Explorer 10+ */
    /*-ms-overflow-style: none;*/
    /*scrollbar-width: none;*/


}

.innerContent::-webkit-scrollbar {
    width: 5px;
}

.innerContent::-webkit-scrollbar-thumb {
    background-color: #888; /* Farbe des beweglichen Teils des Scrollbalkens */
    border-radius: 2px;
}

.innerContent::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

.cursor{
    cursor: pointer;
}

.backGroundBrightnessModal{
    filter: brightness(50%);
}

.backGroundBrightnessInfo{
    filter: brightness(50%);
}

.formElementUser{
    cursor: pointer;
}


/*.checkBoxLabelInput{
    margin-left: 15px;
    font-style: normal;
    padding-left: 10px;
}*/



/* Chrome-spezifisches CSS */
@media screen and (-webkit-min-device-pixel-ratio:0) {
    .checkBoxLabelInput::before {
        content: "";
        display: inline-block;
        width: 5px; /* Gewünschter Abstand zur Checkbox */
        /*height: 40px;*/
    }

    .checkBoxLabelInput {
        margin-left: 20px;
        font-style: normal;
    }
}

/* Firefox-spezifisches CSS */
@-moz-document url-prefix() {
    .checkBoxLabelInput::before {
        content: "";
        display: inline-block;
        width: 5px; /* Gewünschter Abstand zur Checkbox */
        height: 10px;
    }

    .checkBoxLabelInput {
        margin-left: 15px;
        font-style: normal;
    }
}

.row > .headerProgressBarSrc{
    border-spacing: 0;

}

.row.headerProgressSrc{
    display: table;
    width: 100%;
    table-layout: fixed;
    border-spacing: 2px;
    margin-top: 5px;
}


.row:not(.headerProgressSrc) {
    width: 100%;
    margin-top: 0px;
    display: flex;
    justify-content: start;

}

.row.center {
    align-items: center;
}


/*
.row{
    display: table;
    width: 100%;
    table-layout: fixed;
    border-spacing: 2px;
    margin-top: 5px;
}*/

.headerProgressBarSrc{
    display: table-cell;
    position: relative;
    height: 1px;
    padding-left: -10px;
    /* Primary Light */
    background: #FFF7DC;
}

.header-contentHeadline{

}


input.modalInput.missingInformation{
    padding-left: 40px;
    width: calc(100% - 54px) !important;
    border-radius: 5px 5px 0px 0px;
}
.modal_innerConntent.innerContent .warningMessage{

    width: calc(100%) !important;

}


.missingInformation{
    border : 1px solid var(--warning-color);

}

.warning{
    display: none;
    position: absolute;
    top: 15px;
    left: 15px;
    width: 19px;
    height: 15px;
    color : var(--warning-color);
}

.warningMessage{
    display: none;
    color : white;
    background-color: var(--warning-color);
    width: calc(100% - 0px );
}

.warningText{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 145%;
    padding-left: 15px;

    /* identical to box height, or 16px */

    /* White */
    color: #FFFFFF;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 1;
}

.modal_warning{
    display: none;
    position: absolute;
    transform: translateY(-190%) translateX(90%);
    color: var(--warning-color);
}

.modal_warning.missingInformation{
    display: block;
    border: 0px;
    padding: 0;
}

.warning.missingInformation{
    display: block;
    border: 0px;
    padding: 0;
}


p{
    margin: 0;
    padding: 0;
}
.modalQuestionTitles{
    /* Small/Caps */
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 140%;
    padding-bottom: 5px;
    /* or 15px */
    display: flex;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    flex-direction: row;
    justify-content: flex-start;  /*start*/
    align-items: flex-start;
    /* Secondary Light */
    color: #DEE9ED;

    width: calc(100% - 5px);
}

.textSize{
    font-size: 19px;
    font-family: "Lato","Arial";

    font-style: normal;
    font-weight: 500;
    line-height: 140%;

    white-space: pre-line;
    text-align: justify;
    color: white;
}

.modal_innerConntent .textSize{
    font-family: "Lato","Arial";

    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;

}

select option {
    margin: 40px;
    background: rgb(255, 255, 255);
    color: #000000;
}

.flexContainerModal{
    display: flex;
    align-items: center;
}
.flexContainerModalContent{
    flex-grow: 1;
    height: 10%;
    font-size: 16px;
}



.inputAlign{
    text-align: center;
    margin-bottom: 15px;
}


.alignRight{
    margin-left: auto;
    margin-right: 0px;
}


.alignLeft{
    text-align: left;
    margin-right: auto;
    margin-left: 0px;
}

div .halfContent{
    width: 50%;
    display: table-cell;
}
div .seventyfiveContent{
    width: 30%;
    display: table-cell;
}

div .quaterContent{
    width: 25%;
    flex-shrink: 1;
    display: flex;
}

div .fifthContent{
    width: 20%;
    display: table-cell;
}


div .smallContent{

    font-family: 'Lato';
    flex-shrink: 0;
    padding-top: 2px;

}


div .smallContentButtonRight{
    font-family: 'Lato';
    width: 5%;
    display: table-cell;
    padding-top: 2px;
}

div .smallContentButtonLeft{
    font-family: 'Lato';
    width: 5%;
    display: table-cell;
    transform: translateX(-500%) translateY(10%);
}

.singleQuestion{
    padding-bottom: 20px;

}

/*div .largeContent95{
    font-family: 'Lato';
    width: calc(100% - 0px);
    max-width: 100%;
    display: block;
    padding-left: 1px;
    padding-right: 0px;
}*/

div .largeContent95 {
    flex-grow: 1;
    margin-right: 0px; /* Abstand zum Icon */
}

/*div .largeContent95{
    font-family: 'Lato';
    width: 85%;
    display: table-cell;
    padding-right: 15px;
}*/

.centered{
   text-align: center;
}
.boxGap{
    flex: none;
    order: 1;
    flex-grow: 1;
    padding-left: 15px;
}

div .thirdQuaterContend {
    width: 75%;
    display: flex;
    flex-grow: 1;
}

.toolTipBtn{
    width: 100%;
    padding-right: 0px;
    border-radius: 8px;
    color:var(--info-color);
    /*transform: translateY(25%) translateX(-30%);*/

}

.toolTipBtn.hideBtn{
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
}

.toolTipBtn.delete{
    background-color:#cb6b4a;
    color:white;
}

.infoButtonSize{

}

.mrgn15{
    margin-top: 15px;
}

.tippy-content {
    border-radius: 8px;
    white-space: pre-line;
    background-color: white;
    color:black;
    border: 2px solid var(--primary-color);
}




.infoIcon{
    color: var(--info-color);
    display: inline-block;
    width: 20px;
    height: 20px;
    padding-left: 10px;

}

.mainYellow{
    color: var(--info-color);
}

.infoIcon.delete{
    color:white;
}

.icons{

}



.multipleWizardControl{
    margin-top: 15px;
    background-color: rgba(194, 194, 194, 0.37);
}
.multipleWizardInformation{
    color:white;
    padding-left: 8%;
}


.fixedContainer{
    position: fixed;
}

.container::-webkit-scrollbar{
    display: none;
}


@keyframes infoModalAnimation {
    from {transform: translateY(100%);}
    to {transform: translateY(0);}
}
@keyframes infoModalAnimationBigScreen{
    from {transform: translateY(100%);}
    to {transform: translateY(0)
    }
}

.infoModal{
    display: none;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 3;
    background-color: var(--info-color);
    color: var(--secondary-color);
    animation-name: infoModalAnimation;
    animation-duration: 1s;
    transform: translateY(0%);
    overflow: hidden;
}



.infoModalDoneButton{
    border-radius: 5px;
    margin-top: 20px;
    background-color: #FFF7DC;
    width: 100%;
    height: 48px;
    color: var(--primary-color);
    font-size: 17px;
    font-style: normal;
    font-weight: 800;

    border: none;
    box-shadow: none;

    cursor: pointer;

}

.vectorInfoModal {
    padding-right: 10px;
}

.infoModal_Content{
    position: relative;
    height: auto;
    overflow: hidden;
}

.infoModal_innerConntent{
    width: 100%;
    font-weight: 650;
    text-align: justify;
}

.closeButtonInfoModal{
    margin-right: 30px;
    margin-left: 30px;

}

.modalDiv{
    position: absolute;
    width: 100%;
    bottom: 0;
}

@keyframes ModalAnimation {
    from {transform: translateY(100%);}
    to {transform: translateY(0);}
}

@keyframes ModalAnimationBigScreen{
    from {transform: translateY(100%) ;}
    to {transform: translateY(0) ;}
}

.modal {
    display: none;
    position: sticky;
    bottom: 0;
    border-radius: 8px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999999;
    background-color: var(--primary-color);
    color: black;
    box-shadow: 0 4px 10px rgba(0,0,0,.25);
    animation-name: ModalAnimation;
    animation-duration: 1s;
    transform: translateY(0);
    margin-bottom: 1px;

}


.modal_content {
    height: 75vh;
    display: flex;
    flex-direction: column;
    width: auto;
    margin-left: 5%;
    margin-right: 5%;

}

.modal_header{
    position: relative;
    height: 50px;
    top:0;

}

.modal_content_container{
    flex: 1;
    position: relative;
    height: 100%;
    overflow: hidden;
}


.modal_content_innerContainer{
    height: 100%;
    position: relative;

}

.modal_innerConntent{
    position: absolute;
    height: 100%;
    width: 100%;

}

.modal_footer{
    position: relative;
    height: 80px;
    bottom: 0;
    background-color:  var(--primary-color);
}

.modal_headline{
    color: #9badcc;
    text-align: left;
    text-transform: uppercase;
    background-color: var(--primary-color) ;
    padding-top: 15px;
}

.ModalFlexInfo{

}

.ModalFlexInfoOptional{
    margin-left: auto;

}

.modalDoneButton{

    font-family: 'Lato','Calisto MT';
    border-radius: 5px;
    box-shadow: none;
    color: var(--primary-color);
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    box-sizing: border-box;
    margin: 1px;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 12px 30px 0px;
    gap: 13px;
    z-index: 7;


    width: 100%;
    height: 48px;
    bottom: 0;

    border-color: var(--info-color);
    /* Primary */
    border: none;
    background: var(--info-color);

    cursor: pointer;

    /* Inside auto layout */

}

.modalDoneButton.btnDisabled{
    background: var(--secondary-color);
    border:0;

}


.modalDoneButton:hover,
.modalDoneButton:focus {
    color: #333;
    text-decoration: none;
}

.modalDoneButton:active {
    background-image: none;
    outline: 0;
   /* -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);*/
}


.modalDeleteBtn{
    border-radius: 5px;
    font-size: 17px;
    box-sizing: border-box;
    margin: 1px;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 15px 30px 0px;
    gap: 13px;
    z-index: 1;


    width: 100%;
    height: 48px;
    bottom: 0;

    background: transparent;
    border : transparent;
    color: white;

    cursor: pointer;
}

.modalDeleteBtn:hover,
.modalDeleteBtn:focus {
    color: #333;
    text-decoration: none;
}

.hiddenDiv {
    display: none;
}

@media only screen and (min-width: 1080px) {
    .modalDiv{
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        z-index: 1;
    }

    #Placeholder{
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: center;
    }


    .alignRight{
        margin-right: 0px;
    }

    .wholeWizardContainer {
        margin-right: auto;
        margin-left: auto;
        width: 420px;
    }


    .innerContentContainer{
        padding-left: 0px;
        padding-right: 0px;
        position: absolute;
        width: calc(100% - 0px);
        min-height: 100%;
        height: 100%;
    }

    .wholeModalContainer {
        background-color: var(--primary-color);
        padding-right: 20%;
        padding-left: 30%;
    }

    .modal{
        width: 480px;
        animation-name: ModalAnimationBigScreen;
        animation-duration: 1s;
        position: relative;
        right: 25%;
        z-index: 10;
    }

    .infoModal{
        width: 480px;
        animation-name: infoModalAnimationBigScreen;
        overflow: hidden;
        position: relative;
        right: 25%;
        z-index: 5;
    }



}
