.input{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 12px;
    font-family: "Lato","Arial";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    width: calc(100% - 26px);
    -webkit-appearance: none;
    -moz-appearance: textfield;
    min-height: 1.2em;

    /* Secondary Light */
    background: #DEE9ED;
    border-radius: 5px;

    /* Inside auto layout */
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;

    border-width: 1px;
    border-style: solid;
    border-color: #7f7f7f;
    box-shadow: none;
    outline: none;

}

.input-group {
    position: relative;
    display: flex;
    align-items: center;
}

.input-group input {
    /*width: 100%;*/
}


.input-group .input-icon {
    position: absolute;
    right: 15px; /* Setzt das Icon 15px vom rechten Rand entfernt */
    cursor: text;
    font-size: 20px;
    color: var(--primary-color);

}


.checkBoxLabel.blockHelperText .input-icon {
    position: absolute;
    right: 15px; /* Setzt das Icon 15px vom rechten Rand entfernt */
    bottom: 30px;
    cursor: text;
    font-size: 20px;
    color: var(--primary-color);

}


.checkBoxLabel .input-icon {
    position: absolute;
    right: 15px; /* Setzt das Icon 15px vom rechten Rand entfernt */
    bottom: 13px;
    cursor: text;
    font-size: 20px;
    color: var(--primary-color);

}


.rmsc {
    --rmsc-main: #4285f4;
    --rmsc-hover: #f1f3f5;
    --rmsc-selected: #e2e6ea;
    --rmsc-border: #ccc;
    --rmsc-gray: #808080 !important;
    --rmsc-bg: #DEE9ED !important;
    --rmsc-p: 10px;
    --rmsc-radius: 5px;
    --rmsc-h: 38px;

}

.dropdown-container{
    cursor: pointer;
}
.dropdown-heading-value{
    cursor: pointer;
}

input::-webkit-date-and-time-value {
    min-width: 90%;
    text-align: left;
    color: black;
}


.helperText{
    font-family: 'Lato';
    font-style: normal;
    height: 18px;
    color: #DEE9ED;
    font-weight: 700;
    line-height: 145%;
    font-size: 13px;
    line-height: 145%;
}




.select{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 12px;
    gap: 8px;

    font-family: "Lato","Arial";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    color: #808080;
    /*-webkit-appearance: none;*/
    min-width: calc(100% - 4px);
    max-width: 100%;
    height: 48px;

    /* Secondary Light */
    background: #DEE9ED;
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    border-color: #7f7f7f;
    box-shadow: none;
    outline: none;

    /* Inside auto layout */
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;

    outline: none;

    cursor: pointer;

}

.custom-select {
    -webkit-appearance: none; /* Entfernt den Standard-Style in Webkit-Browsern */
    -moz-appearance: none; /* Entfernt den Standard-Style in Mozilla-Browsern */
    appearance: none; /* Entfernt den Standard-Style in modernen Browsern */
    background-image: url("data:image/svg+xml,%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg fill='%23808080' height='20px' width='20px' version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 330 330' xml:space='preserve'%3E%3Cg id='SVGRepo_bgCarrier' stroke-width='0'/%3E%3Cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cg id='SVGRepo_iconCarrier'%3E%3Cpath id='XMLID_225_' d='M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393 c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393 s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z'/%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 10px center;
    padding-right: 30px; /* Platz für das SVG-Icon */
}


.SelectWrapper{
    position: relative;
    display: inline-block;
    min-width: calc(90% + 33px);

}


select option[value=""] {
    color: #808080;                /* Textfarbe des Platzhalters */
    background-color: #f0f0f0;     /* Hintergrundfarbe des Platzhalters */
}

.SelectWrapper::after{
    content: '\0025BC';
    font: normal normal normal 12px/1 FontAwesome;
    color: black;
    right: 14px;
    top: 4px;
    height: 26px;
    padding: 15px 0px 0px 8px;
    position: absolute;
    pointer-events: none;
}


select {
    padding-right: 24px;
}

textarea {
    width: 100%; /* Ersetzen Sie dies durch die gewünschte feste Breite */
    height: 20vh; /* 50% der Höhe des Viewports */
    resize: vertical; /* Ermöglicht dem Benutzer, die Höhe zu ändern, aber nicht die Breite */
}


.formElement{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 12px 0px 12px;
    margin-top: 15px;
    width: calc(100% - 25px);
    height: 54px;
    font-weight: 700;
    font-size: 17px;

    /* Secondary Dark */
    background: var(--secondary-color);
    color: var(--info-color) ;
    border-radius: 5px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

    cursor: pointer;

}

.formElementText{

}

.formElement .textColor{
    color:white;
}

.formElement.linkedVisual {
    color: grey;
    margin-top: 2px;

    cursor: default;
}

.formElementUser{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 12px 0px 12px;
    margin-top: 15px;
    width: Calc(100% - 25px);
    height: 54px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;

    /* Secondary Dark */
    background: var(--secondary-color);
    color: #ffffff;
    border-radius: 5px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.formElememtUserIcon{
    margin-right: 15px;
    align-self: center;
    color:var(--info-color);
}

.formElememtPlusIcon{
    margin-right: 15px;
    color:var(--info-color);
}

.radioSelect{
    border : 1px solid var(--secondary-color);
    color: var(--info-color);
    display: none;

}

.radioSelect:checked{
    display: none;
    color:#e0e0e0;
}


.radioSelectLabel{
    text-align: left;
    content: "\2713";
    line-height: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;

    font-size: 18px;
    width: calc(100% - 10px);
    color:white;
    margin-top: 5px;
}


.radioSelectLabel:active{
  /*  border : 1px solid #FFD951FF;
    color:#FFD951FF; */
}

.radioSelectLabel > .choiceText:active{
    width: calc(100% - 12px);
    border : 1px solid var(--info-color);
    color:var(--info-color);
}

.radioSelectLabel > .choiceText{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 5px 5px 15px;

    width: calc(100% - 10px);
    height: auto;

    /* Secondary Dark */
    background: var(--secondary-color);
    border-radius: 5px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.radioSelect:checked + .choiceText{
    width: calc(100% - 12px);
    border : 1px solid var(--info-color);
    color:var(--info-color);
}

.radio{

}

.checkBox{
    box-sizing: border-box;
    align-items: start;
    width: 20px;
    height: 20px;

    /* White */
    border: 1px solid white;
    border-radius: 5px;
}
input:focus{
    outline: none
}

.checkBoxLabel{
    margin-top:8px;
    display: block;
    position: relative;
    margin-bottom: 30px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #e0e0e0;
    font-family: "Lato","Arial";
}

.checkBoxLabel .checkBox{
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkBoxLabel:hover .checkBox ~ .checkmark {
    /*background-color: #ffd951;*/
}

.checkBoxLabel:hover{
    /*color: #ffd951;*/
}

.checkBoxLabel .checkBox:checked ~ .checkmark {
    background-color: var(--info-color);
}

.checkBox:checked +.checkBoxLabel{
    color: var(--info-color);
}

.checkBoxLabel .checkBox:checked ~ .checkmark:after {
    display: block;
}

.checkBoxLabel .checkmark:after {
    position: absolute;
    left: 5px;
    top: 2px;
    width: 6px;
    height: 7px;
    border: solid var(--secondary-color);
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.checkmark {
    left: 0;
    position: absolute;
    background-color: transparent;
    box-sizing: border-box;

    width: 20px;
    height: 20px;

    /* White */
    border: 1px solid #ffffff;
    border-radius: 5px;
}


.checkmark.missingInformation {
    left: 0;
    position: absolute;
    background-color: transparent;
    box-sizing: border-box;
    align-items: start;
    width: 20px;
    height: 20px;

    /* White */
    border: 1px solid var(--warning-color);
    border-radius: 5px;
}


.checkmark:after {
    content: "";
    display: none;
}

.checkBox:checked ~ .checkmark:after {
    display: block;
}

.multipleCheckBox{

}

.modal_innerConntent.innerContent .SelectWrapper{
    width:10%;
}

.modal_innerConntent.innerContent .input{
    width: calc(100% - 30px);
}

.modal_innerConntent.innerContent .multiselect{
    width: calc(100% - 5px);
    height: 45px;
}

.input.missingInformation{
    padding-left: 40px;
    width: calc(100% - 54px);
    border-radius: 5px 5px 0px 0px;
}


input::placeholder {

    /*color: rgba(0, 0, 0, 0.9); Schwarz mit 50% Transparenz */
    color: #808080; /* Ersetzen Sie dies mit der gewünschten Farbe */
    opacity: 1; /* Optional, um die Standardtransparenz zu überschreiben */

}


/* Für Internet Explorer 10-11 */
input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.9);
}

.calendar-icon {
    color: var(--secondary-color);
}

@media only screen and (min-width: 1080px){
    .formElement{
        width: Calc(100% - 25px);
    }
    .select{

        /*min-width: calc(90% + 40px);*/


    }


    .input.missingInformation{
        padding-left: 40px;
        width: calc(100% - 53px);
        border-radius: 5px 5px 0px 0px;
    }

}

