:root {
    --primary-color: #236E84;
    --secondary-color: #165B70;
    --info-color: #FFD951FF;
    --warning-color: #FF4A6B;
    --third-color: #04b60b;
    --forth-color: #b9b3b3;
    --fifth-color: #1d6ebb;


}


/* --primary-color: #007bff; #ff9900 */
/* background-color: #236E84; */