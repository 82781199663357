
.nextColor{
    background-color:  var(--info-color);
    color: var(--primary-color);
}

.saveColor{
    background-color: #5a831c;
    color:white;
}

.btnSrc.backColor{
    background: var(--primary-color);
    color:white;
}

.btnSrc.btnDisabled{
    background: var(--secondary-color);
}

.nextColor.btnSrc.btnDisabled{
    border:0;
}

.icon_disabled{
    color: var(--primary-color);
}

.vectorRight{

}

.vectorLeft{
}

.btnSrc{
    display: flex;
    flex-direction: row;
    /*align-items: flex-start;*/
    padding: 12px 24px 12px 24px;

    width: 151px;
    height: 48px;

    /* Primary */
    background: var(--info-color);

    border: none;
    box-shadow: none;
    border-radius: 5px;

    font-family: 'Lato','Calisto MT';
    font-style: normal;
    font-weight: 800;
    font-size: 17px;
    line-height: 20px;

    align-items: center;
    text-align: center;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 1;

    cursor: pointer;
}

.btnSrc.singular{
    width: 100%;
    font-style: normal;
    font-weight: 800;
    font-size: 17px;
    line-height: 20px;
    /*display: block;*/
    align-items: center;
    text-align: center;
}

.backColor.btnSrc{
   border-color:transparent;
}
.nextColor.btnSrc{
   border-color: var(--info-color);
}
.saveColor.btnSrc{
   /* border-color:greenyellow;*/
}

.btnSrc:hover,
.btnSrc:focus {
    /*color: #333;*/
    text-decoration: none;
}
.btnSrc:active {
    background-image: none;
    outline: 0;
   /* -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);*/
}

.formButton.btnSrc{
    margin-top: 15px;
    margin-left: 15px;
    /*background-image: linear-gradient(180deg,var(--info-color) 69.27%,#ffbb56);*/
    float: left;
}
