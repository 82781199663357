@media (min-width: 1280px) {
    .rmAhv {
        font-size: 1.125rem;
    }
}

.rmAhv {
    letter-spacing: 0.15em;
    font-size: 1.04167rem;
}

.gwtdTi {
    text-transform: uppercase;
    font-weight: 700;
    line-height: 1.35;
    letter-spacing: 0.1em;
    text-align: center;
}

.fMtbac {
    display: flex;
    -moz-box-align: center;
    align-items: center;
    margin: 2rem 0px;
}
.bSMhBC {
    margin: 0px 0px 0px 2rem;
}

.djiQjh {
    margin: 1.5rem 0px;
}

/*
*, ::before, ::after {
     border-width: 0px;
     border-style: solid;
     box-sizing: border-box;
 }*/

.etUxOQ:not(:last-child) {
    border-bottom: 1px solid rgb(224, 215, 195);
}

.etUxOQ {
    display: flex;
    -moz-box-align: center;
    align-items: center;
    -moz-box-pack: justify;
    justify-content: space-between;
    padding: 0.75rem 0px;
    line-height: 1.2;
    font-weight: 700;
}

.cZqjSD {
    text-align: left;
}
.ecFwR {
    text-align: right;
}
