.autocomplete-container {
    position: relative;
}

.autocomplete-container input {
    width: 100%;
    /*padding: 10px;*/
    font-size: 16px;
}


.autocomplete-container .dropdown {
    position: absolute;
    bottom: 100%; /* Dies positioniert die Dropdown-Liste direkt über dem Eingabefeld */
    left: 0;
    /*top: calc(100%);*/ /* 5px Abstand zur Inputbox */
    width: calc(100% - 6px); /* Breite des Dropdown-Menüs anpassen */
    max-height: 120px; /* Höhe der Dropdown-Liste anpassen */
    overflow-y: auto; /* Scrollbar anzeigen, wenn die Liste länger ist */
    background-color: #DEE9ED;
    border: 1px solid #ccc;
    padding: 0;
    margin: 0;
    list-style: none;
}

.autocomplete-container .dropdown li {
    padding: 10px;
    cursor: pointer;
}

.autocomplete-container .dropdown li:hover {
    background-color: #f0f0f0;
}