.progressBarDiv{

}

.contentSplitBox{

}

.SplitBoxText{
    color: black;
    padding-top: 12px ;
    width: 34px;
}
.locked{
    color: var(--info-color);
}
.notLocked{
    color: #FFFFFF;
    opacity: 0.5;
}

.SplitBoxFlexBox{
    display: flex;
}
.percentageHeader{
    display: table;
    width: 100%;
    table-layout: fixed;
    border-spacing: 2px;
}
div .percentageContent{
    width: 65%;
    display: table-cell;
}

div .percentageObject{
    width: 30%;
    display: table-cell;
}

.inputField{
    display: flex;
    flex-direction: row;
    padding: 0px 12px 0px 12px;
    gap: 8px;
    align-content: center;
    text-align: center;
    margin-left: 20px;
    bottom: 0;

    width: 80px;
    height: 48px !important;

    /* Secondary Light */
    background: #DEE9ED;
    border-radius: 5px;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}

.SplitBoxIconStyle{
    text-align:end;
}

.buttonStyleProgress{
    border-color: transparent;
    background-color: #DEE9ED;

    cursor: pointer;
}

.rowHeaderProgress{
    display: table-cell;
    position: relative;

    width: 100%;
}
.rowContentPercentage{
    display: table-cell;
    position: relative;
    width: 50%;
    align-items: flex-start;
}

.SplitBoxModal {
    display: none;
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999999;
    background-color: var(--primary-color);
    color: black;
    box-shadow: 0 4px 10px rgba(0,0,0,.25);
    animation-name: SplitBoxModalAnimation;
    animation-duration: 1s;
    transform: translateY(0);
    margin-bottom: 1px;

}


.SplitBoxModal_content {
    height: 600px;
    display: flex;
    flex-direction: column;
    width: auto;
    margin-left: 5%;
    margin-right: 5%;

}

.SplitBoxModal_header{
    position: relative;
    height: 50px;
    top:0;
}
.SplitBoxModal_content_container{
    flex: 1;
    position: relative;
    height: 100%;
    overflow: hidden;
}

.SplitBoxModal_content_innerContainer{
    height: 100%;
    position: relative;
}

.SplitBoxModal_innerConntent{
    position: absolute;
    height: 100%;
    width: 100%;
}

.SplitBoxModal_footer{
    position: relative;
    height: 80px;
    bottom: 0;
    background-color: var(--primary-color);
}

.SplitBoxModal_headline{
    color: #9badcc;
    text-align: left;

    background-color: var(--primary-color);
}



.SplitBoxModalDoneButton{

    border-radius: 5px;
    color: var(--primary-color);
    font-size: 17px;
    box-sizing: border-box;
    margin: 1px;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 15px 30px 0px;
    gap: 13px;
    z-index: 7;


    width: 100%;
    height: 48px;
    bottom: 0;
    /*
     width: auto;
    height: 80px;

       */



    /* Secondary */

    /* Secondary Dark */
    border-top: 1px solid var(--secondary-color);


    /* Primary */
    background: var(--info-color);

}

.SplitBoxModalDeleteBtn{
    background: transparent;
    border : transparent;
    color: #7f7f7f;
}

@media only screen and (min-width: 1080px) {
    div .percentageContent{
        width: 65%;
        display: table-cell;
    }

}
